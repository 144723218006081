import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import FacebookLogo from "../images/facebook.svg"
import RedirectSvg from "../images/redirect.svg"
import GoogleLogo from "../images/gmailLogo.svg"
import AnchorLink from "./anchor-link"
import { useLocation } from "@reach/router"
import { Box } from "./primitives/base"

export const FranchiseFooter = ({ data }) => {
	const location = useLocation()
	const franchiseData = data?.franchise
	const countryData = data?.country

	const socialmedia = useStaticQuery(graphql`
		query {
			country(countryId: { eq: "1" }) {
				facebook_sm_link
				instagram_link
			}
		}
	`)

	console.log(socialmedia, "socialmedia")

	return (
		<div
			css={{
				width: "100%",
				display: "flex",
				alignItems: "flex-start",
				paddingTop: "92px",
				flexDirection: "column",
				backgroundColor: "#0F0F0F",
				"@media (max-width: 1000px)": {
					paddingTop: "48px",
				},
				"@media (max-width: 612px)": {
					paddingTop: "48px",
				},
			}}
		>
			<div
				className="container-md"
				css={{
					width: "100%",
					display: "flex",
					alignItems: "flex-start",
					marginLeft: "auto",
					marginRight: "auto",
					justifyContent: "space-between",
					paddingBottom: "92px!important",
					"@media (max-width: 1000px)": {
						flexDirection: "column",
						paddingBottom: "48px!important",
					},
					"@media (max-width: 612px)": {
						alignItems: "center",
						flexDirection: "column",
					},
				}}
			>
				<div
					css={{
						width: "100%",
						display: "flex",
						maxWidth: "23%",
						alignItems: "flex-start",
						flexDirection: "column",
						marginRight: "20px",
						"&>*:not(style)~*:not(style)": {
							marginTop: "12px",
						},
						"@media (max-width: 1000px)": {
							maxWidth: "100%",
							alignItems: "center",
							"&>*:not(style)~*:not(style)": {
								marginTop: "8px",
							},
						},
						"@media (max-width: 612px)": {
							maxWidth: "100%",
							alignItems: "center",
						},
					}}
				>
					<h4
						className="h4"
						css={{
							color: "#FFFFFF",
							marginBottom: "8px",
						}}
					>
						{franchiseData?.name || `trapoFit`}
					</h4>
					{/* franchiseData && (
						<a
							href={`https://${process.env.GATSBY_PUBLIC_NEXT_URL}/book/${
								franchiseData?.id ? franchiseData.id : ""
							}`}
						>
							<p
								className="p"
								css={{
									color: "#707070",
									transition: "all 0.3s ease-in-out",
									"&:hover": {
										color: "#ffffff",
									},
								}}
								onClick={() => {
									window.dataLayer.push({ event: `booking_click_footer` })
								}}
							>
								Buchungstool
							</p>
						</a>
					) */}
					{countryData && (
						<AnchorLink to="#ueber-uns">
							<span
								className="p"
								css={{
									color: "#707070",
									transition: "all 0.3s ease-in-out",
									"&:hover": {
										color: "#ffffff",
									},
								}}
							>
								Über uns
							</span>
						</AnchorLink>
					)}
					{countryData && (
						<AnchorLink to="#filialfinder">
							<span
								className="p"
								css={{
									color: "#707070",
									transition: "all 0.3s ease-in-out",
									"&:hover": {
										color: "#ffffff",
									},
								}}
							>
								Werkstatt finden
							</span>
						</AnchorLink>
					)}
					<Link to="https://trapofit-jobs.de">
						<span
							className="p"
							css={{
								color: "#707070",
								transition: "all 0.3s ease-in-out",
								"&:hover": {
									color: "#ffffff",
								},
							}}
						>
							Jobs
						</span>
					</Link>
					{franchiseData && (
						<AnchorLink to="#team">
							<span
								className="p"
								css={{
									color: "#707070",
									transition: "all 0.3s ease-in-out",
									"&:hover": {
										color: "#ffffff",
									},
								}}
							>
								Team
							</span>
						</AnchorLink>
					)}
					{!location.pathname.includes("/de/blog") && (
						<Link to="/de/blog">
							<span
								className="p"
								css={{
									color: "#707070",
									transition: "all 0.3s ease-in-out",
									"&:hover": {
										color: "#ffffff",
									},
								}}
							>
								Blog{" "}
								<RedirectSvg
									css={{
										objectFit: "cover",
										marginLeft: "4px",
										display: "inline",
										width: "10px",
										height: "10px",
									}}
								/>
							</span>
						</Link>
					)}
					{(franchiseData || location.pathname.includes("/de/blog")) && (
						<Link to="/de">
							<span
								className="p"
								css={{
									color: "#707070",
									transition: "all 0.3s ease-in-out",
									"&:hover": {
										color: "#ffffff",
									},
								}}
							>
								trapofit Deutschland{" "}
								<RedirectSvg
									css={{
										objectFit: "cover",
										marginLeft: "4px",
										display: "inline",
										width: "10px",
										height: "10px",
									}}
								/>
							</span>
						</Link>
					)}
				</div>
				<div
					css={{
						width: "100%",
						display: "flex",
						maxWidth: "23%",
						alignItems: "flex-start",
						flexDirection: "column",
						marginRight: "20px",
						"&>*:not(style)~*:not(style)": {
							marginTop: "12px",
						},
						"@media (max-width: 1000px)": {
							maxWidth: "100%",
							marginTop: "24px",
							alignItems: "center",
							"&>*:not(style)~*:not(style)": {
								marginTop: "8px",
							},
						},
					}}
				>
					<h4
						className="h4"
						css={{
							color: "#FFFFFF",
							marginBottom: "8px",
						}}
					>
						Kontakt
					</h4>
					<a href={`mailto:${franchiseData?.mail || countryData?.mail}`}>
						<p
							className="p"
							css={{
								wordBreak: "break-word",
								transition: "all 0.3s ease-in-out",
								color: "#707070",
								transition: "all 0.3s ease-in-out",
								"&:hover": {
									color: "#ffffff",
								},
								"@media (max-width: 1000px)": {
									textAlign: "center",
								},
							}}
							onClick={() => {
								window.dataLayer.push({ event: `email_footer_click` })
							}}
						>
							{franchiseData?.mail || countryData?.mail}
						</p>
					</a>
					<a href={`tel:${franchiseData?.phone || countryData?.phone}`}>
						<p
							className="p"
							css={{
								width: "fit-content",
								transition: "all 0.3s ease-in-out",
								color: "#707070",
								transition: "all 0.3s ease-in-out",
								"&:hover": {
									color: "#ffffff",
								},
							}}
							onClick={() => {
								window.dataLayer.push({ event: `phone_click_footer` })
							}}
						>
							{franchiseData?.phone || countryData?.phone}
						</p>
					</a>
					<div
						css={{
							width: "100%",
							alignItems: "center",
							display: "flex",
							color: "#707070",
							transition: "all 0.3s ease-in-out",
							"@media (max-width: 1000px)": {
								justifyContent: "center",
							},
							"& a": {
								cursor: "pointer",
							},
							"& svg:hover": {
								color: "var(--primary-color-variation)",
							},
						}}
					>
						{/*}
						{(franchiseData?.facebook_sm_link || countryData?.facebook_sm_link) && (
							<Link
								to={franchiseData?.facebook_sm_link || countryData?.facebook_sm_link}
							>
								<FacebookLogo
									css={{
										width: "28px",
										objectFit: "cover",
										marginRight: "8px",
										transition: "all 0.3s ease-in-out",
									}}
								/>
							</Link>
						)}
								{*/}
						{franchiseData && franchiseData?.place?.link && (
							<Link to={franchiseData?.place.link}>
								<GoogleLogo
									css={{
										width: "28px",
										objectFit: "cover",
										transition: "all 0.3s ease-in-out",
									}}
								/>
							</Link>
						)}
					</div>
				</div>
				{franchiseData && (
					<div
						css={{
							width: "100%",
							display: "flex",
							maxWidth: "23%",
							alignItems: "flex-start",
							flexDirection: "column",
							marginRight: "20px",
							"@media (max-width: 1000px)": {
								maxWidth: "100%",
								marginTop: "32px",
								alignItems: "center",
							},
							"@media (max-width: 612px)": {
								maxWidth: "100%",
								marginTop: "32px",
								alignItems: "center",
							},
						}}
					>
						<h4
							className="h4"
							css={{
								color: "#FFFFFF",
								marginBottom: "28px",
								"@media (max-width: 1000px)": {
									marginBottom: "8px",
								},
							}}
						>
							Adresse
						</h4>
						<Link
							to={franchiseData?.place?.link}
							className="p"
							dangerouslySetInnerHTML={{ __html: franchiseData?.address }}
							css={{
								color: "#707070",
								marginBottom: "12px",
								transition: "all 0.3s ease-in-out",
								lineHeight: "2.1!important",
								marginTop: "-14px",
								"&:hover": {
									color: "#ffffff",
								},
								"@media (max-width: 1000px)": {
									marginTop: "0",
									textAlign: "center",
									marginBottom: "10px",
									maxWidth: "250px",
									"& *": {
										textAlign: "center",
									},
								},
							}}
						/>
					</div>
				)}
				{(socialmedia?.country?.instagram_link ||
					socialmedia?.country?.facebook_sm_link) && (
					<div
						css={{
							width: "100%",
							display: "flex",
							maxWidth: "23%",
							alignItems: "flex-start",
							flexDirection: "column",
							marginRight: "20px",
							"@media (max-width: 1000px)": {
								maxWidth: "100%",
								marginTop: "32px",
								alignItems: "center",
							},
							"@media (max-width: 612px)": {
								maxWidth: "100%",
								marginTop: "32px",
								alignItems: "center",
							},
						}}
					>
						<h4
							className="h4"
							css={{
								color: "#FFFFFF",
								marginBottom: "28px",
								"@media (max-width: 1000px)": {
									marginBottom: "8px",
								},
							}}
						>
							Social Media
						</h4>
						{socialmedia?.country?.facebook_sm_link && (
							<a
								href={socialmedia?.country?.facebook_sm_link}
								className="p"
								target="_blank"
								rel="noreferrer noopener"
								css={{
									color: "#707070",
									marginBottom: "12px",
									transition: "all 0.3s ease-in-out",
									lineHeight: "2.1!important",
									marginTop: "-14px",
									"&:hover": {
										color: "#ffffff",
									},
									"@media (max-width: 1000px)": {
										marginTop: "0",
										textAlign: "center",
										marginBottom: "10px",
										maxWidth: "250px",
										"& *": {
											textAlign: "center",
										},
									},
								}}
							>
								Facebook
							</a>
						)}
						{socialmedia?.country?.instagram_link && (
							<a
								href={socialmedia?.country?.instagram_link}
								className="p"
								target="_blank"
								rel="noreferrer noopener"
								css={{
									color: "#707070",
									marginBottom: "12px",
									transition: "all 0.3s ease-in-out",
									lineHeight: "2.1!important",
									marginTop: "-14px",
									"&:hover": {
										color: "#ffffff",
									},
									"@media (max-width: 1000px)": {
										marginTop: "0",
										textAlign: "center",
										marginBottom: "10px",
										maxWidth: "250px",
										"& *": {
											textAlign: "center",
										},
									},
								}}
							>
								Instagram
							</a>
						)}
					</div>
				)}
				{(!(
					socialmedia?.country?.instagram_link ||
					socialmedia?.country?.facebook_sm_link
				) ||
					!franchiseData) && (
					<div
						css={{
							width: "100%",
							display: "flex",
							alignItems: "flex-start",
							flexDirection: "column",
							"@media (max-width: 1000px)": {
								maxWidth: "100%",
								marginTop: "32px",
								alignItems: "center",
								display: "none",
							},
							"@media (max-width: 612px)": {
								maxWidth: "100%",
								marginTop: "32px",
								alignItems: "center",
							},
							"&>*:not(style)~*:not(style)": {
								marginTop: "12px",
							},
						}}
					/>
				)}
			</div>
			<div
				className="container-xl"
				css={{
					width: "100%",
					display: "flex",
					alignItems: "center",
					marginLeft: "auto",
					marginRight: "auto",
					paddingBottom: "28px",
					justifyContent: "center",
					flexDirection: "column",
				}}
			>
				<span
					css={{
						paddingBottom: "28px",
						borderTop: "1px solid #707070",
						width: "100%",
					}}
				/>
				<div
					css={{
						color: "#707070",
						fontSize: "16px",
						textAlign: "center",
						lineHeight: "20px",
						"@media (max-width: 384px)": {},
					}}
				>
					©{new Date().getFullYear()} trapofit. All Rights Reserved{" "}
					<Box xl={{ display: "inline" }} sm={{ display: "none" }}>
						{" "}
						|{" "}
					</Box>
					<Box as="br" xl={{ display: "none" }} sm={{ display: "initial" }} />
					<span>
						<Link
							to="/impressum"
							css={{
								transition: "all 0.3s ease-in-out",
								"&:hover": { color: "var(--primary-color-variation)" },
							}}
						>
							Impressum
						</Link>
						{" | "}
					</span>
					<span>
						<Link
							to="/agb"
							css={{
								transition: "all 0.3s ease-in-out",
								"&:hover": { color: "var(--primary-color-variation)" },
							}}
						>
							Geschäftsbedingungen
						</Link>
						{" | "}
					</span>
					<span>
						<Link
							to="/datenschutz"
							css={{
								transition: "all 0.3s ease-in-out",
								"&:hover": { color: "var(--primary-color-variation)" },
							}}
						>
							Datenschutz
						</Link>
					</span>
				</div>
			</div>
		</div>
	)
}

const Footer = FranchiseFooter
export default Footer
